<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1>Signup Sion Academy</h1>
                <p class="text-muted">Silakan masukkan data yang diminta dan kirim.</p>
                <CInput
                  v-model=name
                  placeholder="Nama"
                  autocomplete="name">
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  v-model=email
                  placeholder="Email (harus gmail)"
                  autocomplete="email"
                  prepend="@"
                />
                <CInput
                  v-model=mobile
                  placeholder="No HP"
                  autocomplete="mobile"
                  prepend="No Hp"
                />
                <CInput
                  v-model=campus
                  placeholder="Nama kampus/universitas/sekolah"
                  autocomplete="campus"
                  prepend="Kampus"
                />
                <CInput
                  v-model=major
                  placeholder="Jurusan"
                  autocomplete="major"
                  prepend="Jurusan"
                />
                <CInput
                  v-model=yearin
                  placeholder="Tahun angkatan"
                  autocomplete="yearin"
                  prepend="Angkatan"
                />
                <CInput
                  v-model=city
                  placeholder="Kota"
                  autocomplete="city"
                  prepend="Kota"
                />
                <CInput
                  v-model=mentor
                  placeholder="Nama pembimbing"
                  autocomplete="mentor">
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>

                <CButton color="success" block @click="sendInquiry" :disabled="disableButton">Kirim</CButton>
              </CForm>
              
              <p style="text-align:center; margin-top:20px">
                <a href="https://sion-academy.org">Sion Academy</a>
              </p>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import api from "../../api/api"

export default {
  data() {
    return {
      name: '',
      email: '',
      mobile: '',
      campus: '',
      major: '',
      yearin: '',
      city: '',
      mentor: '',
    };
  },

  methods: {
    async sendInquiry(){
      this.$confirm("Anda sudah pastikan seluruh data yang dimasukkan sudah benar?").then(() => {
        this.email = this.email.toLowerCase()
        if(this.email.indexOf("@gmail.com")>0)
          this.execSendInquiry()
        else
          this.$alert("Mohon menggunakan gmail. Terima kasih.")
      });
    },

    async execSendInquiry() {
      let apiRes = await api.signup (this.name, this.email, this.mobile, this.campus, this.major, this.yearin, this.city, this.mentor)
      if (apiRes.result === 'OK') {
        this.$alert("Silakan cek email Anda dari Sion Academy untuk mengaktifkan akun.")
        this.$router.replace('/login', () => {})
      }
      else {
        if(apiRes.err==="ALREADY EXIST")
          this.$alert("Email Anda sudah terdaftar. Silakan ke halaman utama untuk login.")
        else{
          console.log("apiRes: " + JSON.stringify(apiRes))
          this.$alert("Maaf, ada kesalahan sistem. Mohon mencoba kembali. Terimakasih!")
        }
      }
    }
  },

  computed: {
    disableButton() {
      return (this.name==='' || this.name.length>50 || 
        this.email==='' || this.email.length>50 || 
        this.mobile==='' || this.mobile.length>20 || 
        this.campus==='' || this.campus.length>30 || 
        this.major==='' || this.major.length>30 || 
        this.city==='' || this.city.length>30 || 
        this.yearin==='' || this.yearin.length!=4 || 
        this.mentor==='' || this.mentor.length>30)
    }
  }
}
</script>
